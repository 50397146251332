$backgroundColor: #efe5da;

.headerContainer {
  width: 100%;
  background-color: $backgroundColor;
  height: 145px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    .logo {
      img {
        width: 141px;
        height: 141px;
      }
    }
    .list {
      ul {
        list-style: none;
        display: flex;
        gap: 25px;

        li {
          color: #fb2056;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

    .list-mobile {
      display: none;
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: #fb2056;
          transform-origin: left;
          transition: all 1s ease;
        }
      }

      &.active {
        .hamburger {
          span {
            &:first-child {
              background-color: #fb2056;
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              opacity: 0;
            }
            &:last-child {
              background-color: #fb2056;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    .list-mobile-menu {
      width: 300px;
      height: 100vh;
      background-color: #fb2056;
      position: fixed;
      top: 100px;
      right: -300px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 1s ease;
      visibility: hidden;

      &.active {
        right: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: white;
        width: 60%;

        li {
          margin-bottom: 25px;
          font-size: inherit;
          color: inherit;
          cursor: pointer;
          // a {
          //   text-decoration: none;
          // }

          &:hover {
            font-weight: 500;
          }
        }
      }
    }

    .icons {
      display: flex;
      gap: 10px;

      a {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .small-screen-icons {
    display: none;
    justify-content: center;
    gap: 20px;
    a {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    width: 95% !important;

    .list {
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .headerContainer {
    .header {
      width: 90% !important;
      .list {
        display: none;
      }
      .list-mobile {
        display: block;
      }
      .list-mobile-menu {
        visibility: visible;
      }
      .icons {
        display: none;
      }
    }
    .small-screen-icons {
      display: flex;
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    width: 90% !important;
  }
}

@media screen and (max-width: 900px) {
  .header {
    width: 90%;
  }
}

@media screen and (min-width: 900px) {
  .header {
    width: 70%;
  }
}
