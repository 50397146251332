.contactFormContainer {
  width: 100%;
  background-image: linear-gradient(#7a3eb7a8, #7a3eb7a8),
    /* color overlay */ url("../../images/team1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .contactForm {
    display: flex;
    gap: 100px;

    .contactFormLeft {
      flex: 1;

      .contactFormLeftAlign {
        max-width: 560px;
        margin-left: auto;
        position: relative;
        top: 150px;
        color: white;
        // align-items: end;

        h2 {
          font-size: 34px;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          margin-top: 20px;
        }
      }
    }

    .contactFormRight {
      flex: 1;
      height: 600px;

      .contactFormRightAlign {
        max-width: 570px;
        background-color: #efe7d8;
        height: 100%;
        padding: 10px;

        form {
          display: flex;
          flex-direction: column;
          input,
          textarea {
            padding: 15px;
            font-size: 16px;
            margin-bottom: 10px;
            margin-top: 5px;
            border: none;
          }

          button {
            background-color: #fb2056;
            color: white;
            border: none;
            outline: navajowhite;
            padding: 20px;
            max-width: 120px;
            font-size: 16px;
            border-radius: 50px;
            font-weight: bold;
            cursor: pointer;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .contactFormContainer {
    height: 920px;
    padding: 20px;

    .contactForm {
      flex-direction: column;
      gap: 50px;

      .contactFormLeft {
        .contactFormLeftAlign {
          top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .contactFormContainer {
    height: 1000px;
  }
}

@media screen and (max-width: 313px) {
  .contactFormContainer {
    height: 1100px;
  }
}
