$backgroundColor: #efe5da;

.contactUsContainer {
  width: 100%;
  background-color: $backgroundColor;
  height: 500px;

  .contactUs {
    h2 {
      text-align: center;
      position: relative;
      top: 70px;
      font-size: 34px;
    }

    .contactUsData {
      position: relative;
      top: 100px;
      display: flex;
      justify-content: center;
      gap: 100px;

      .dataLeft {
        flex: 1;

        .dataLeftAlign {
          max-width: 520px;
          margin-left: auto;
          align-items: end;

          h3 {
            font-size: 32px;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }

      .dataRight {
        flex: 1;
        border-left: 2px solid #fb2056;
        padding: 5px 0 5px 20px;

        h3 {
          font-size: 32px;
          margin-bottom: 20px;
        }

        h4 {
          font-size: 20px;
          margin-bottom: 10px;
          margin-top: 15px;
        }

        .icons {
          display: flex;
          gap: 10px;

          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .contactUsContainer {
    height: 700px;
    padding: 20px;

    .contactUs {
      h2 {
        top: 30px;
      }

      .contactUsData {
        top: 50px;
        flex-direction: column;
        gap: 50px;
      }
    }
  }
}
