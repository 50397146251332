.accordianContainer {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  //   flex-wrap: wrap;
  gap: 20px;
  text-align: left;

  .accordian {
    width: 100%;
    // padding: 20px;
    background-color: white;

    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      width: 100%;
      fill: black;
      color: black;
      transition: color 0.15s ease, fill 0.15s ease;
      font-weight: 600;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      //   border-bottom: 5px solid black;

      &:hover {
        color: #fb2056;
        fill: #fb2056;
      }

      p {
        font-size: 18px;
        font-weight: 700;
      }

      .direction {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 15px;
        font-size: 25px;
        font-weight: 600;
      }
    }

    .content-data {
      // padding: 10px 20px 20px 20px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease, padding 0.5s ease;

      &.open {
        max-height: 500px; /* Adjust based on your content height */
        padding: 10px 20px 20px 20px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .accordianContainer {
    width: 95%;
  }
}
