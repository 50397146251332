$backgroundColor: #efe5da;
$textColor: #fb2056;

.playToWinContainer {
  width: 100%;
  height: 1000px;
  background-color: $backgroundColor;

  .playToWin {
    position: relative;
    top: 100px;
    .playToWinContent {
      display: flex;
      justify-content: center;
      gap: 50px;
      padding-top: 20px;

      .playToWinContentLeft {
        position: relative;
        height: max-content;
        top: 200px;
        .hr-line {
          margin-top: 20px;
          width: 70px;
          height: 3px;
          background-color: $textColor;
        }

        p {
          margin-top: 20px;
          line-height: 25px;
          color: #404040;
          font-size: 16px;
          max-width: 350px;
        }
      }

      .playToWinContentRight {
        display: flex;
        gap: 20px;

        .playToWinContentImgSec1 {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .image1 {
            width: 360px;
            height: 370px;
            border-radius: 10px;
          }

          .image2 {
            width: 360px;
            height: 370px;
            border-radius: 10px;
          }
        }

        .playToWinContentImgSec2 {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .image3 {
            width: 360px;
            height: 600px;
            border-radius: 10px;
          }

          .image4 {
            width: 360px;
            height: 140px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .playToWinContainer {
    height: 1050px;
    .playToWin {
      top: 0px;
      .playToWinContent {
        flex-direction: column;
        align-items: center;
        gap: 50px;

        .playToWinContentLeft {
          top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .playToWinContainer {
    height: 1700px;
    .playToWin {
      .playToWinContent {
        .playToWinContentRight {
          flex-direction: column;
          .playToWinContentImgSec1 {
            .image1 {
              width: 350px;
              height: 350px;
            }

            .image2 {
              width: 350px;
              height: 350px;
            }
          }

          .playToWinContentImgSec2 {
            .image3 {
              width: 350px;
              height: 500px;
            }

            .image4 {
              width: 350px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .playToWinContainer {
    height: 1400px;
    .playToWin {
      .playToWinContent {
        .playToWinContentRight {
          .playToWinContentImgSec1 {
            .image1 {
              width: 250px;
              height: 250px;
            }

            .image2 {
              width: 250px;
              height: 250px;
            }
          }

          .playToWinContentImgSec2 {
            .image3 {
              width: 250px;
              height: 400px;
            }

            .image4 {
              width: 250px;
            }
          }
        }
      }
    }
  }
}
