.footer {
  width: 100%;
  height: 150px;
  background-color: black;
  text-align: center;
  color: white;

  p {
    position: relative;
    top: 40px;
    margin-bottom: 20px;
  }
}
