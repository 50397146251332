.gamesContainer {
  width: 100%;
  height: 600px;
  background-image: url("../../images/back.png");
  background-size: cover;

  .games {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    position: relative;
    top: 50px;
    .game {
      img {
        border-radius: 40px;
      }
    }
  }
}

@media screen and (max-width: 1076px) {
  .gamesContainer {
    height: 1200px;
    padding-top: 20px;
  }
}

@media screen and (max-width: 706px) {
  .gamesContainer {
    height: 1750px;
  }
}

@media screen and (max-width: 336px) {
  .gamesContainer {
    height: 1500px;
    .games {
      .game {
        img {
          height: 421px;
        }
      }
    }
  }
}
