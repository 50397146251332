.questionsContainer {
  width: 100%;
  height: 700px;
  background-image: url("../../images/questions.png");
  background-size: cover;

  .questions {
    text-align: center;

    h2 {
      position: relative;
      top: 70px;
      font-size: 34px;
    }

    .questionsAccord {
      position: relative;
      top: 120px;
    }
  }
}
