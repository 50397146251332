$backgroundColor: #e2d8cd;
$textColor: #fb2056;

.downloadContainer {
  width: 100%;
  background-color: $backgroundColor;
  height: 800px;

  .download {
    text-align: center;

    .downloadDisplay {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 100px;
      height: 800px;

      .downloadLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        max-width: 530px;
        // height: fit-content;

        .hr-line {
          width: 130px;
          height: 3px;
          background-color: $textColor;
        }

        p {
          line-height: 25px;
          text-align: left;
        }

        a {
          text-decoration: none;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 210px;
          height: 60px;
          background-color: $textColor;
          padding: 17px 40px;
          border-radius: 30px;
          color: white;
          font-weight: 500;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .downloadDisplay {
    gap: 50px;
  }
}

@media screen and (max-width: 730px) {
  .downloadDisplay {
    height: 500px;
    .downloadRight {
      display: none;
    }
  }
}
