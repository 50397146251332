$backgroundColor: #efe5da;
$textColor: #fb2056;

.frontContainer {
  width: 100%;
  background-color: $backgroundColor;
  height: calc(100vh - 145px);

  .front {
    text-align: center;

    .frontDisplay {
      display: flex;
      justify-content: center;
      gap: 50px;
      padding-top: 20px;

      .displayLeft {
        text-align: center;
        max-width: 460px;
        height: fit-content;
        position: relative;
        top: 100px;

        h6 {
          font-weight: 500;
          font-size: 14px;
          color: $textColor;
          margin-bottom: 20px;
        }

        h1 {
          font-size: 40px;
          margin-bottom: 20px;
        }

        p {
          line-height: 25px;
          color: #404040;
          font-size: 16px;
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          padding-top: 20px;

          button,
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 14px;
            width: 210px;
            height: 60px;
            background-color: $textColor;
            border: none;
            padding: 17px 40px;
            border-radius: 30px;
            color: white;
            font-weight: 500;
            cursor: pointer;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .displayRight {
        img {
          object-fit: contain;
          width: 400px;
          height: 600px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .frontDisplay {
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .displayRight {
    display: none;
  }
}

@media screen and (min-width: 320px) {
  .frontDisplay {
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .displayRight {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .frontDisplay {
    flex-direction: row;
    align-items: start;
  }

  .displayRight {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}
